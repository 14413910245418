import { useRecoilState, useRecoilValue } from 'recoil';
import { format, formatDate } from 'date-fns';
import { activeCompanyState } from '../atoms/new.company.atom';
import AssigneeTaskList from '../components/Dashboard/AssigneeTaskList';
import CompanyTaskList from '../components/Dashboard/CompanyTaskList';
import { activeAuths, activeUserState } from '../atoms/user.atom';
import { User } from '../models/general';
import TeamScheduleViewer from '../components/Dashboard/TeamScheduleViewer';
import WorkLoadHeatMap from '../components/Dashboard/WorkLoadHeatMap';
import { keyMilestones } from '../atoms/project.atom';
import MilestoneTable from '../components/Dashboard/MilestoneTable';
import MilestoneList from '../components/Dashboard/MilestoneTable';

const Main = () => {
  const activeCompany = useRecoilValue(activeCompanyState);
  const activeUser = useRecoilValue(activeUserState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const milestoneValues = useRecoilValue(keyMilestones);
  console.log(milestoneValues);
  return (
    <>
      {activeAuthorizations.hasOwner && (
        <div className=" bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl text-bold">
            {activeCompany.name} Dashboard -{' '}
            <span className=" text-gray-500">
              {format(new Date(), 'MMM d, yyyy')}
            </span>{' '}
          </h2>
          <div className="grid grid-cols-3">
            <div className="col-span-1 px-1 ">
              <h2 className="text-xl font-bold">Monthly Goal Status</h2>
              <div className="border-gray-400 h-56 border rounded p-4">
                <p>
                  Delivered Work: <span className="font-bold">!</span> of 4
                </p>
                <p>
                  Signed Jobs: <span className="font-bold">!</span> of 3
                </p>
                <p>
                  Foundations: <span className="font-bold">!</span> of 8
                </p>
              </div>
              <div className="my-1 grid grid-cols-3">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-2 py-1 rounded"
                  onClick={() => {
                    return;
                  }}
                >
                  Call
                </button>
                <button
                  type="submit"
                  className="bg-gray-300 text-white col-start-3 px-2 py-1 rounded"
                  onClick={() => {
                    return;
                  }}
                >
                  Message
                </button>
              </div>
            </div>
            <div className="col-span-1  px-1 ">
              <h2 className="text-xl font-bold">Target Deliveries</h2>
              <div className="border-gray-400 h-56 border rounded p-4">
                <p>List of Projects: </p>
              </div>
            </div>
            <div className="col-span-1  px-1 ">
              <h2 className="text-xl font-bold">Imoortant Dates This Month</h2>
              <div className="border-gray-400 h-56 border rounded p-4">
                <p>Independence Day: July 4th, 22222</p>
                <p>Scheduled Start: July 45th, 22222</p>
                <p>Scheduled Delivery: July 45th, 22222</p>
              </div>
            </div>
            <div className="col-span-1  px-1 ">
              <h2 className="text-xl font-bold">Company Library</h2>
              <div className="border-gray-400 h-56 border rounded p-4">
                <p>Onboarding Guide</p>
                <p>CAD conventions</p>
                <p>Quickbooks Time - How To</p>
              </div>
            </div>
            <div className="col-span-1  px-1 ">
              <h2 className="text-xl font-bold">Important Files</h2>
              <div className="border-gray-400 h-56 border rounded p-4">
                <p>COMING SOON</p>
              </div>
            </div>
            <div className="col-span-1  px-1 ">
              <h2 className="text-xl font-bold">Recent Activity</h2>
            </div>
          </div>
        </div>
      )}
      <div className=" bg-white mt-6 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl text-bold">My Tasks:</h2>
        <AssigneeTaskList user={activeUser || ({} as User)} weeksToShow={1} />
      </div>
      {activeAuthorizations.hasOwner && (
        <div className=" bg-white mt-6 p-6 rounded-lg shadow-lg">
          <TeamScheduleViewer />
        </div>
      )}
      {activeAuthorizations.hasOwner && milestoneValues && (
        <div className=" bg-white mt-6 p-6 rounded-lg shadow-lg">
          <WorkLoadHeatMap companyId={activeCompany._id} />
          {/* {milestoneValues.map((mile) => {
            return (
              <>
                <div>
                  title: {mile.title} - {mile.projectName} - $
                  {mile.invoiceAmount} -{' '}
                  {mile.targetDate
                    ? formatDate(mile.targetDate || Date(), 'MMM dd')
                    : 'NEEDS DATE'}
                </div>
              </>
            );
          })} */}

          <MilestoneList milestoneValues={milestoneValues} />
        </div>
      )}
      <div className=" bg-white mt-6 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl text-bold">
          Need help with a task or technical question, ask Abbi: Coming soon.
        </h2>
      </div>
    </>
  );
};
export default Main;
