import React, { useEffect, useState } from 'react';
import {
  createUser,
  fetchCompanies,
  fetchCompany,
  updateCompany,
  updateUser,
} from '../utils/api';
import { User, Company, UserAuths, UserType } from '../models/general';
import { useRecoilValue } from 'recoil';
import { activeAuths, activeUserState } from '../atoms/user.atom';
import { activeCompanyState } from '../atoms/new.company.atom';
import EditCompanyPopup from './EditCompanyPopUp';

export type NewUser = {
  company?: string;
  username: string;
  userType: string;
  active: boolean;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  authorizations?: string[];
};

const BuilderHQCompanyAdmin: React.FC = () => {
  const [showEditCompanyPopup, setShowEditCompanyPopup] =
    useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAddUserPopup, setShowAddUserPopup] = useState<boolean>(false);
  const [showEditUserPopup, setShowEditUserPopup] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null,
  );
  const [newUser, setNewUser] = useState<NewUser>({
    username: '',
    userType: 'Employee',
    active: true,
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    authorizations: [],
  });

  const [authorizations, setAuthorizations] = useState<string[]>([]);
  const [newAuthorization, setNewAuthorization] = useState<UserAuths | ''>('');

  const useCompanies = async () => {
    setLoading(true);
    try {
      const response = await fetchCompanies();
      setCompanies(response.data);
    } catch (e) {
      setError('Error fetching companies');
    } finally {
      setLoading(false);
    }
  };

  const useCompany = async () => {
    setLoading(true);
    try {
      const response = await fetchCompany(
        activeUser.company._id || activeUser.company,
      );
      setCompanies([response.data]);
    } catch (e) {
      setError('Error fetching company');
    } finally {
      setLoading(false);
    }
  };

  const loadCompanies = activeAuthorizations.hasSuperUser
    ? useCompanies
    : useCompany;

  useEffect(() => {
    loadCompanies();
  }, []);

  const addUser = async (companyId: string, newUser: NewUser) => {
    try {
      const company = await fetchCompany(companyId);

      const userResp = await createUser({ ...newUser });
      const updatedUsers = [...company.data.users, userResp.data];
      setCompanies((prev) =>
        prev.map((comp) =>
          comp._id === companyId ? { ...comp, users: updatedUsers } : comp,
        ),
      );
    } catch (e) {
      setError('Error adding user');
      console.log(e);
    }
  };

  const handleEditCompanyClick = (company: Company) => {
    setSelectedCompany(company);
    setShowEditCompanyPopup(true);
  };

  const handleAddUserClick = (companyId: string) => {
    setSelectedUser(null);
    setSelectedCompanyId(companyId);
    setShowAddUserPopup(true);
  };

  const handleEditUserClick = (companyId: string, user: User) => {
    setSelectedCompanyId(companyId);
    setSelectedUser(user);
    setNewUser({
      ...user,
      authorizations: user.authorizations || [],
    });
    setAuthorizations(user.authorizations || []);
    setShowEditUserPopup(true);
  };

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditUserSubmit = async () => {
    if (selectedCompanyId && selectedUser) {
      try {
        const updatedUser: User = {
          ...newUser,
          company: selectedCompanyId,
          username: newUser.email.toLowerCase(),
          email: newUser.email.toLowerCase(),
          authorizations,
        };

        await updateUser(updatedUser._id, updatedUser);
        loadCompanies();
        setShowEditUserPopup(false);
      } catch (e) {
        setError('Error updating user');
      }
    }
  };

  const handleEditCompanySubmit = async () => {
    if (selectedCompany) {
      try {
        await updateCompany(selectedCompany._id, selectedCompany);
        setCompanies((prev) =>
          prev.map((comp) =>
            comp._id === selectedCompany._id ? selectedCompany : comp,
          ),
        );
        setShowEditCompanyPopup(false);
      } catch (e) {
        setError('Error updating company');
      }
    }
  };

  const handleAddUserSubmit = () => {
    if (selectedCompanyId) {
      const lowerCaseEmail = newUser.email.toLowerCase();
      addUser(selectedCompanyId, {
        ...newUser,
        company: selectedCompanyId,
        userType: 'Employee',
        active: true,
        email: lowerCaseEmail,
        username: lowerCaseEmail,
        authorizations,
      });
      setShowAddUserPopup(false);
      setNewUser({
        username: '',
        userType: 'Employee',
        active: true,
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        authorizations: [],
      });
      setAuthorizations([]);
    }
  };

  const handleAddAuthorization = () => {
    if (newAuthorization.trim() !== '') {
      setAuthorizations((prev) => [...prev, newAuthorization]);
      setNewAuthorization('');
    }
  };

  const handleRemoveAuthorization = (index: number) => {
    setAuthorizations((prev) => prev.filter((_, i) => i !== index));
  };

  const filteredCompanies = companies.filter(
    (company) =>
      company.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company?.users?.some(
        (user) =>
          user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
  );

  if (loading)
    return <div className="text-center text-lg font-semibold">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 font-semibold">{error}</div>
    );

  return (
    <div className="container mx-auto p-6">
      {activeAuthorizations.hasSuperUser ? (
        <>
          <h1 className="text-3xl font-bold mb-6 text-center">
            Builder HQ - God View
          </h1>
          <div className="mb-6">
            <input
              type="text"
              placeholder="Search companies or users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
          </div>
        </>
      ) : (
        <h1 className="text-3xl font-bold mb-6 text-center">
          {activeCompany.name} - Company Management
        </h1>
      )}

      {filteredCompanies.length > 0 ? (
        filteredCompanies.map((company) => (
          <div
            key={company._id}
            className="mb-8 border p-6 rounded-lg shadow-lg bg-white"
          >
            <h2 className="text-2xl font-semibold mb-4">
              {company.name}{' '}
              <button
                className=" text-sm bg-green-500 text-white px-4 py-1 rounded hover:bg-green-600 mb-4"
                onClick={() => handleEditCompanyClick(company)}
              >
                Edit Company
              </button>
            </h2>

            <ul className="list-disc list-inside mb-4">
              {company.users?.map((user) => (
                <li
                  key={user._id}
                  className="flex justify-between items-center mb-2"
                >
                  <span>
                    {user.firstName} {user.lastName} - ({user.email})
                  </span>
                  <button
                    className="bg-yellow-500 text-white px-4 py-1 rounded hover:bg-yellow-600 mr-2"
                    onClick={() => handleEditUserClick(company._id, user)}
                  >
                    Edit User
                  </button>
                  {/* <button
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                    onClick={() => removeUser(company._id, user._id)}
                  >
                    Remove User
                  </button> */}
                </li>
              ))}
            </ul>
            <button
              className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
              onClick={() => handleAddUserClick(company._id || '')}
            >
              Add User
            </button>
          </div>
        ))
      ) : (
        <div className="text-center text-gray-500 font-semibold">
          No companies or users found.
        </div>
      )}

      {showEditCompanyPopup && selectedCompany && (
        <EditCompanyPopup
          company={selectedCompany}
          onClose={() => setShowEditCompanyPopup(false)}
          onSave={handleEditCompanySubmit}
          setSelectedCompany={setSelectedCompany}
        />
      )}

      {(showAddUserPopup || showEditUserPopup) && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-2xl font-semibold mb-4">
              {selectedUser ? 'Edit User' : 'Add New User'}
            </h2>
            <div className="mb-4">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={newUser.firstName}
                onChange={handleUserInputChange}
                className="w-full p-2 border border-gray-300 rounded mb-2"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={newUser.lastName}
                onChange={handleUserInputChange}
                className="w-full p-2 border border-gray-300 rounded mb-2"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={newUser.email}
                onChange={handleUserInputChange}
                className="w-full p-2 border border-gray-300 rounded mb-2"
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={newUser.phone}
                onChange={handleUserInputChange}
                className="w-full p-2 border border-gray-300 rounded mb-2"
              />
              <select
                name="userType"
                value={newUser.userType}
                onChange={handleUserInputChange}
                className="w-full p-2 border border-gray-300 rounded mb-2"
              >
                {Object.values(UserType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <div className="mb-2">
                <label className="mr-2">Active:</label>
                <input
                  type="checkbox"
                  name="active"
                  checked={newUser.active}
                  onChange={(e) =>
                    setNewUser((prev) => ({
                      ...prev,
                      active: e.target.checked,
                    }))
                  }
                />
              </div>
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Authorizations</h3>
                <div className="flex mb-2">
                  <select
                    value={newAuthorization}
                    onChange={(e) =>
                      setNewAuthorization(e.target.value as UserAuths)
                    }
                    className="w-full p-2 border border-gray-300 rounded mr-2"
                  >
                    <option value="">Select Authorization</option>
                    {Object.values(UserAuths).map((auth) => (
                      <option key={auth} value={auth}>
                        {auth}
                      </option>
                    ))}
                  </select>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    onClick={handleAddAuthorization}
                  >
                    Add
                  </button>
                </div>
                <ul className="list-disc list-inside">
                  {authorizations.map((auth, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <span>{auth}</span>
                      <button
                        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                        onClick={() => handleRemoveAuthorization(index)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600"
                onClick={() => {
                  setShowAddUserPopup(false);
                  setShowEditUserPopup(false);
                  setSelectedUser(null);
                  setNewUser({
                    username: '',
                    userType: 'Employee',
                    active: true,
                    email: '',
                    phone: '',
                    firstName: '',
                    lastName: '',
                    authorizations: [],
                  });
                }}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={
                  selectedUser ? handleEditUserSubmit : handleAddUserSubmit
                }
              >
                {!selectedUser ? 'Add User' : 'Update User'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuilderHQCompanyAdmin;
