import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectListState } from '../../atoms/project.atom';
import { Milestone, Project } from '../../models/general';
import {
  fetchProjectsByClient,
  fetchProjectsByCompany,
  updateProject,
} from '../../utils/api';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { addDays, format, isBefore, isToday } from 'date-fns';

// Import @hello-pangea/dnd components
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// Import classifications from utils/misc.ts
import { classifications } from '../../utils/misc';
import ProjectSchedulePreview from './ProjectSchedulePreview';

// Assuming you have a GanttChart component
// import GanttChart from './GanttChart';

const phaseOptions = [
  'Prospective Jobs',
  'Pre Work',
  'Drawings In Progress',
  'AHJ Review',
  'Closed - Construction',
  'On Hold',
];

const phaseWorkflowStatusMap = {
  'Prospective Jobs': [
    'initiated',
    'estimating',
    'estimate - finished',
    'letter agreement - sent',
    'letter agreement - signed',
    'Deposit - Sent',
    'Deposit - Paid',
  ],
  'Pre Work': ['scheduling - needs schedule', 'scheduling - waiting to start'],
  'Drawings In Progress': [
    'schematics - in progress',
    'schematics - blocked',
    'schematics - finished',
    'design plans - in progress',
    'design plans - blocked',
    'design plans - finished',
  ],
  'AHJ Review': [
    'AHJ - submitted',
    'AHJ - has comments',
    'AHJ - addressing comments',
    'AHJ - approved',
  ],
  'Closed - Construction': ['closed', 'closed - lost'],
  'On Hold': ['holding'],
};

export function ProjectsTable() {
  const navigate = useNavigate();
  const activeCompany = useRecoilValue(activeCompanyState);
  const [searchText, setSearchText] = useState<string>('');
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false);
  const [leadFilter, setProjectLeadFilter] = useState<string | null>(null);
  const [phaseFilter, setPhaseFilter] = useState<string>('all');
  const [classificationFilter, setClassificationFilter] =
    useState<string>('all');
  const [expandedProjects, setExpandedProjects] = useState<string[]>([]);
  const [projects, setProjects] = useRecoilState(projectListState);

  useEffect(() => {
    const fetchCompanyData = async (companyId: string) => {
      console.log('getting company data');
      setIsLoadingProjects(true);
      try {
        const response = await fetchProjectsByCompany(companyId);
        setIsLoadingProjects(false);
        setProjects(response.data);
      } catch (error) {
        setIsLoadingProjects(false);
        console.error('Failed to fetch projects', error);
      }
    };
    const fetchClientData = async (companyId: string) => {
      console.log('getting client data');
      setIsLoadingProjects(true);
      try {
        const response = await fetchProjectsByClient(companyId);
        setIsLoadingProjects(false);
        setProjects(response.data);
      } catch (error) {
        setIsLoadingProjects(false);
        console.error('Failed to fetch projects', error);
      }
    };
    if (
      activeCompany &&
      activeCompany.subscriptionLevel &&
      activeCompany.subscriptionLevel !== 'Guest'
    ) {
      fetchCompanyData(activeCompany._id);
    } else if (activeCompany) {
      fetchClientData(activeCompany._id);
    }
  }, []);

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handlePhaseFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPhaseFilter(event.target.value);
  };

  const handleClassificationFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setClassificationFilter(event.target.value);
  };

  const toggleExpand = (projectId: string) => {
    setExpandedProjects((prev) =>
      prev.includes(projectId)
        ? prev.filter((id) => id !== projectId)
        : [...prev, projectId],
    );
  };

  const isExpanded = (projectId: string) =>
    expandedProjects.includes(projectId);

  const handleProjectLeadFilter = (leadId: string | null) => {
    setProjectLeadFilter(leadId);
  };

  const filteredProjects = projects
    ? projects
        .filter((project) => {
          const matchesSearchText =
            project.name.toLowerCase().includes(searchText.toLowerCase()) ||
            project.client.name
              .toLowerCase()
              .includes(searchText.toLowerCase());

          const matchesProjectLead =
            !leadFilter || (project.lead && project.lead._id === leadFilter);

          const matchesPhase =
            phaseFilter === 'all' ||
            (phaseWorkflowStatusMap[phaseFilter] &&
              phaseWorkflowStatusMap[phaseFilter].includes(
                project.workFlowStatus,
              ));

          const matchesClassification =
            classificationFilter === 'all' ||
            project.classification === classificationFilter;

          return (
            matchesSearchText &&
            matchesProjectLead &&
            matchesPhase &&
            matchesClassification
          );
        })
        // Sort projects by sequence value
        .sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
    : [];

  const milestoneStatus = (project: Project) => {
    const milestones: Milestone[] = project.milestones || [];

    // Find the milestone with the lowest sequence number that is not completed
    const incompleteMilestones = milestones
      .filter((milestone) => !milestone.completionDate)
      .sort((a, b) => a.sequence - b.sequence);

    if (incompleteMilestones.length === 0) {
      return { status: 'Completed', dueDate: null };
    }
    const nextMilestone = incompleteMilestones[0];
    const now = new Date();
    const dueDate = nextMilestone.targetDate;

    if (!dueDate) {
      return { status: 'No Due Date', dueDate: null };
    }

    if (isBefore(dueDate, now) || isToday(dueDate)) {
      return { status: 'Overdue', dueDate };
    } else if (isBefore(dueDate, addDays(now, 3))) {
      return { status: 'Due Soon', dueDate };
    } else {
      return { status: 'Not Due', dueDate };
    }
  };

  // Get unique project leads
  const leads = Array.from(
    new Set(
      projects
        .map((project) => project.lead)
        .filter((lead) => lead && lead._id)
        .map((lead) => JSON.stringify(lead)),
    ),
  ).map((leadStr) => JSON.parse(leadStr));

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Check if the position has actually changed
    if (source.index === destination.index) return;

    // Create a copy of the filtered projects
    const reorderedProjects = Array.from(filteredProjects);

    // Remove the dragged project and insert it at the new position
    const [movedProject] = reorderedProjects.splice(source.index, 1);
    reorderedProjects.splice(destination.index, 0, movedProject);

    // Create a mapping of project IDs to their new sequence numbers
    const updatedSequenceMap = {};
    reorderedProjects.forEach((project, index) => {
      updatedSequenceMap[project._id] = index;
    });

    // Update the sequence numbers in the main projects list
    const updatedProjects = projects.map((project) => {
      if (updatedSequenceMap.hasOwnProperty(project._id)) {
        return {
          ...project,
          sequence: updatedSequenceMap[project._id],
        };
      }
      return project;
    });

    // Update the state
    setProjects(updatedProjects);

    // Update the sequences on the server
    try {
      await Promise.all(
        updatedProjects.map((project) => {
          if (updatedSequenceMap.hasOwnProperty(project._id)) {
            return updateProject(project._id, { sequence: project.sequence });
          }
        }),
      );
    } catch (error) {
      console.error('Failed to update project sequences', error);
    }
  };

  return (
    <div>
      <div className="my-4 pb-2 border-b-2 border-black grid grid-cols-4">
        <label className="px-5">
          <input
            type="text"
            className="rounded border border-solid border-neutral-200 px-3"
            value={searchText}
            onChange={handleSearchTextChange}
            placeholder="Search projects..."
          />
        </label>
        <div className="flex space-x-2">
          {leads.map((lead) => (
            <button
              key={lead._id}
              onClick={() => handleProjectLeadFilter(lead._id)}
              className={`w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center ${
                leadFilter === lead._id ? 'ring-2 ring-blue-500' : ''
              }`}
              title={`${lead.firstName} ${lead.lastName}`}
            >
              {lead.firstName[0]}
              {lead.lastName[0]}
            </button>
          ))}
          <button
            onClick={() => handleProjectLeadFilter(null)}
            className={`w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center ${
              !leadFilter ? 'ring-2 ring-blue-500' : ''
            }`}
          >
            All
          </button>
        </div>
        <label className="px-5">
          Phase:
          <select
            value={phaseFilter}
            onChange={handlePhaseFilterChange}
            className="ml-2"
          >
            <option value="all">All</option>
            {phaseOptions.map((phase) => (
              <option key={phase} value={phase}>
                {phase}
              </option>
            ))}
          </select>
        </label>
        <label className="px-5">
          Classification:
          <select
            value={classificationFilter}
            onChange={handleClassificationFilterChange}
            className="ml-2"
          >
            <option value="all">All</option>
            {classifications.map((classification) => (
              <option key={classification} value={classification}>
                {classification}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="grid grid-cols-7 capitalize text-lg font-bold border-b">
        <div className="text-center">Status</div>
        <div>Project Description</div>
        <div className="text-center">Client</div>
        <div className="text-center">Disciplines</div>
        <div className="text-center">Workflow Status</div>
        <div></div>
        <div></div>
      </div>
      {isLoadingProjects ? (
        <div className="text-center text-lg font-semibold my-10">
          Loading...
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="projects">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {filteredProjects.map((project: Project, index) => {
                  const { status, dueDate } = milestoneStatus(project);

                  return (
                    <Draggable
                      key={project._id}
                      draggableId={project._id}
                      index={index}
                    >
                      {(provided) => (
                        <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="grid grid-cols-7 items-center py-2 border-b">
                              <div className="flex flex-col items-center">
                                <span
                                  className={`w-4 h-4 rounded-full ${
                                    status === 'Overdue'
                                      ? 'bg-red-500'
                                      : status === 'Due Soon'
                                        ? 'bg-yellow-500'
                                        : status === 'Not Due'
                                          ? 'bg-green-500'
                                          : 'bg-gray-500'
                                  }`}
                                  title="Next delivery date"
                                ></span>
                                <span className="ml-2 text-center text-xs">
                                  {dueDate
                                    ? format(dueDate, 'MM-dd-yyyy')
                                    : 'No Due Date'}
                                </span>
                              </div>
                              <div>
                                {project.name} - {project.address}
                              </div>
                              <div className="bg-gray-200 rounded text-center">
                                {project.client?.name || 'Data Missing'}
                              </div>
                              <div
                                className={`grid grid-cols-2 gap-1 text-center`}
                              >
                                {project.disciplines.map((discipline) => (
                                  <div
                                    key={discipline}
                                    className="rounded text-xs"
                                  >
                                    {discipline}
                                  </div>
                                ))}
                              </div>
                              <div className="text-center">
                                {project.workFlowStatus}
                              </div>
                              <div className="text-center">
                                <button
                                  onClick={() =>
                                    navigate(`/project/${project._id}`)
                                  }
                                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                                >
                                  Edit
                                </button>
                              </div>
                              <div className="text-center">
                                <button
                                  onClick={() => toggleExpand(project._id)}
                                  className="text-xl"
                                >
                                  {isExpanded(project._id)
                                    ? 'Hide Schedule ▲'
                                    : 'Show Schedule ▼'}
                                </button>
                              </div>
                            </div>
                          </div>
                          {isExpanded(project._id) && (
                            <div className="col-span-7 p-4 bg-gray-100">
                              <ProjectSchedulePreview project={project} />
                            </div>
                          )}
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}
