import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { fetchTasksByAssignee, fetchUsersByCompany } from '../../utils/api';
import {
  format,
  subWeeks,
  addWeeks,
  isWithinInterval,
  formatDate,
  parseISO,
} from 'date-fns';
import AssigneeTaskList from './AssigneeTaskList';

const TeamScheduleViewer = () => {
  const activeCompany = useRecoilValue(activeCompanyState);
  const [employees, setEmployees] = useState([]);
  const [expandedUsers, setExpandedUsers] = useState<string[]>([]);
  const [tasksByEmployee, setTasksByEmployee] = useState<Record<string, any[]>>(
    {},
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const companyResponse = await fetchUsersByCompany(activeCompany._id);
        setEmployees(companyResponse.data);
        console.log('Employees fetched:', companyResponse.data); // Debugging log
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, [activeCompany._id]);

  const fetchTasksForEmployee = async (employeeId: string) => {
    try {
      const response = await fetchTasksByAssignee(employeeId);
      const filteredTasks = response.data.filter((task) => {
        const now = new Date();
        const twoWeeksBefore = subWeeks(now, 2);
        const twoWeeksAfter = addWeeks(now, 2);
        const taskDate = parseISO(task.startDate);
        return isWithinInterval(taskDate, {
          start: twoWeeksBefore,
          end: twoWeeksAfter,
        });
      });
      setTasksByEmployee((prev) => ({ ...prev, [employeeId]: filteredTasks }));
      console.log(`Tasks fetched for employee ${employeeId}:`, response.data); // Debugging log
    } catch (error) {
      console.error(`Failed to fetch tasks for employee ${employeeId}`, error);
    }
  };

  const toggleExpand = (employeeId: string) => {
    setExpandedUsers((prev) =>
      prev.includes(employeeId)
        ? prev.filter((id) => id !== employeeId)
        : [...prev, employeeId],
    );

    if (!tasksByEmployee[employeeId]) {
      fetchTasksForEmployee(employeeId);
    }
  };

  const isExpanded = (employeeId: string) => expandedUsers.includes(employeeId);

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Team Schedule Viewer</h2>
      <div className="space-y-4">
        {employees?.map((employee) => (
          <div key={employee._id} className="border p-4 rounded">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold">
                  {employee.firstName} {employee.lastName}
                </h3>
              </div>
              <div className="text-center">
                <button
                  onClick={() => toggleExpand(employee._id)}
                  className="text-xl"
                >
                  {isExpanded(employee._id) ? 'Hide Tasks ▲' : 'Show Tasks ▼'}
                </button>
              </div>
            </div>
            {isExpanded(employee._id) && (
              <AssigneeTaskList user={employee} weeksToShow={2} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamScheduleViewer;
