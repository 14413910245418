import BuilderHQCompanyAdmin from '../components/BuilderHQAdmin';

const CompanyManagementPage: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <BuilderHQCompanyAdmin />
    </div>
  );
};

export default CompanyManagementPage;
