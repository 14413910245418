import React from 'react';
import { parseISO, startOfWeek, format, isValid } from 'date-fns';
import { formatCurrency } from '../../utils/misc';
import { useNavigate } from 'react-router-dom';

interface Milestone {
  _id: string;
  title: string;
  projectName: string;
  targetDate?: string;
  invoiceAmount: number;
}

interface MilestoneListProps {
  milestoneValues: Milestone[];
}

const MilestoneList: React.FC<MilestoneListProps> = ({ milestoneValues }) => {
  const navigate = useNavigate();
  // Group milestones by week start date and calculate total for each week
  const weeksData = milestoneValues.reduce<
    Record<string, { milestones: Milestone[]; total: number }>
  >((acc, mile) => {
    if (mile.targetDate) {
      const weekStart = startOfWeek(parseISO(mile.targetDate), {
        weekStartsOn: 0,
      });
      const weekKey = isValid(weekStart)
        ? format(weekStart, 'yyyy-MM-dd')
        : 'Invalid Date';

      if (!acc[weekKey]) {
        acc[weekKey] = { milestones: [], total: 0 };
      }

      acc[weekKey].milestones.push(mile);
      acc[weekKey].total += mile.invoiceAmount;
    } else {
      // For items without a targetDate, use a separate key
      if (!acc['noDate']) {
        acc['noDate'] = { milestones: [], total: 0 };
      }
      acc['noDate'].milestones.push(mile);
      acc['noDate'].total += mile.invoiceAmount;
    }
    return acc;
  }, {});

  // Sort weeks by date, excluding 'noDate' section
  const sortedWeeks = Object.keys(weeksData)
    .filter((key) => key !== 'noDate')
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return (
    <div>
      {sortedWeeks.map((weekKey) => (
        <div
          key={weekKey}
          style={{
            marginBottom: '20px',
            borderBottom: '1px solid #ccc',
            paddingBottom: '10px',
          }}
        >
          <h3>
            Week of {format(parseISO(weekKey), 'MMM d, yy')}{' '}
            <span className="font-bold">
              :: {formatCurrency(weeksData[weekKey].total)}
            </span>
          </h3>
          <ul>
            {weeksData[weekKey].milestones.map((mile) => (
              <li
                className="cursor-pointer"
                key={mile._id}
                onClick={() => {
                  navigate(`/project/${mile.project}/schedule`);
                }}
              >
                {mile.title} - {mile.projectName} - ${mile.invoiceAmount} -{' '}
                {mile.targetDate
                  ? format(parseISO(mile.targetDate), 'MMM dd')
                  : 'NEEDS DATE'}
              </li>
            ))}
          </ul>
        </div>
      ))}

      {/* Section for items without a targetDate */}
      {weeksData['noDate'] && (
        <div
          style={{
            marginTop: '20px',
            borderTop: '2px solid #ccc',
            paddingTop: '10px',
          }}
        >
          <h3>
            Items Without a Target Date{' '}
            <span className="font-bold">
              :: {formatCurrency(weeksData['noDate'].total)}
            </span>
          </h3>
          <ul>
            {weeksData['noDate'].milestones.map((mile) => (
              <li
                className="cursor-pointer"
                key={mile._id}
                onClick={() => {
                  navigate(`/project/${mile.project}/schedule`);
                }}
              >
                title: {mile.title} - {mile.projectName} - ${mile.invoiceAmount}{' '}
                - NEEDS DATE
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MilestoneList;
