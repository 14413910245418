import React, { ReactElement } from 'react';
import SideNav from '../components/SideNav';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default function OriginalLayout({ children }: Props): ReactElement {
  return (
    <div className="bg-gray-200">
      {/* <TopNav /> */}
      <div className="grid grid-cols-10 gap-6 font-sans">
        <div className="col-span-1 bg-black h-screen sm:font-sans">
          <SideNav />
        </div>
        <div className="col-span-9 p-4 h-screen justify-start items-center bg-gray-200 overflow-scroll">
          {children}
        </div>
      </div>
    </div>
  );
}
