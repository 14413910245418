import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Modal, Radio, Select, Tooltip } from 'antd'; // Assuming you are using antd for date picker
import { Milestone, Project } from '../models/general';
import { compareAsc, formatDate } from 'date-fns';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';
import { createMilestone, updateMilestone } from '../utils/api';
import { useRecoilState } from 'recoil';
import { milestonesState } from '../atoms/project.atom';
import { milestoneTitles } from '../utils/misc';

const { Option } = Select;

const MilestoneList: React.FC<{
  project: Project;
  isViewOnly: boolean;
}> = ({ project, isViewOnly }) => {
  const [activeMilestones, setActiveMilestones] =
    useRecoilState(milestonesState);

  useEffect(() => {
    console.log('checking for miles');
    if (project.milestones) setActiveMilestones(project.milestones);
  }, [project._id]);
  const [filter, setFilter] = useState<'all' | 'completed' | 'incomplete'>(
    'all',
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMilestoneTitle, setNewMilestoneTitle] = useState('');
  const [selectedTitle, setSelectedTitle] = useState(''); // For selected predefined title

  const handleMarkAsComplete = async (milestone: Milestone) => {
    if (isViewOnly) return;
    let val = new Date();
    if (milestone.completionDate) {
      val = null;
    }
    // THIS REWRITE IS WORKING, but NOT RESETTING THE COMPLETED DATE WHEN CLICKED
    // rewrite with call to update milestone
    // updateProject({ ...project, milestones: updatedMilestones });
    try {
      if (!milestone._id) {
        alert('OLD MILESTONE FOUND, changes not saved');
        return;
      }
      const updatedMilestone = await updateMilestone(milestone._id, {
        completionDate: val,
      });
      console.log('updating', updateMilestone);
      const updatedMilestones = activeMilestones.map((miles) =>
        miles.title === milestone.title ? updatedMilestone.data : miles,
      );
      setActiveMilestones(updatedMilestones);
    } catch (e) {
      alert('Milestone Failed to Save');
      console.log(e);
    }
  };

  const handleTargetDateChange = async (date: any, milestone: Milestone) => {
    if (isViewOnly) return;

    try {
      if (!milestone._id) {
        alert('OLD MILESTONE FOUND, changes not saved');
        return;
      }
      const updatedMilestone = await updateMilestone(milestone._id, {
        targetDate: date ? date.toDate() : null,
      });
      console.log('updating', updateMilestone);
      const updatedMilestones = activeMilestones.map((miles) =>
        miles.title === milestone.title ? updatedMilestone.data : miles,
      );
      setActiveMilestones(updatedMilestones);
    } catch (e) {
      alert('Milestone Failed to Save');
      console.log(e);
    }
  };

  const handleAddMilestone = async () => {
    const finalTitle =
      newMilestoneTitle ||
      selectedTitle ||
      `Milestone ${activeMilestones.length + 1}`;
    const newMilestoneResponse = await createMilestone({
      title: finalTitle,
      sequence: activeMilestones.length,
      project: project._id,
      targetDate: undefined,
      completionDate: undefined,
    });
    const updatedMilestones = [...activeMilestones, newMilestoneResponse.data];
    setActiveMilestones(updatedMilestones);
    setIsModalVisible(false); // Close the modal after adding milestone
    setNewMilestoneTitle(''); // Clear the input after submission
    setSelectedTitle('');
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewMilestoneTitle(''); // Clear the input if the modal is cancelled
  };

  const handleFilterChange = (e: any) => {
    setFilter(e.target.value);
  };

  const filteredMilestones = activeMilestones
    .filter((milestone) => {
      if (filter === 'completed') {
        return milestone.completionDate;
      } else if (filter === 'incomplete') {
        return !milestone.completionDate;
      }
      return true; // If "all" is selected, show all milestones
    })
    .sort((a, b) => {
      if (a.sequence == undefined || b.sequence == undefined) return 0;
      return a.sequence - b.sequence;
    });

  return (
    <div>
      <Radio.Group
        onChange={handleFilterChange}
        value={filter}
        style={{ marginBottom: 16 }}
      >
        <Radio.Button value="all">All</Radio.Button>
        <Radio.Button value="completed">Completed</Radio.Button>
        <Radio.Button value="incomplete">Future</Radio.Button>
      </Radio.Group>
      {filteredMilestones.map((milestone, index) => (
        <div key={index} className="milestone-item">
          <h3 className="font-bold">
            {milestone.title}{' '}
            {milestone.completionDate ? (
              <Tooltip title={isViewOnly ? '' : 'Mark as Not Completed'}>
                <button
                  className="bg-blue-500 text-white text-sm px-2 py-1 my-1 rounded"
                  onClick={() => handleMarkAsComplete(milestone)}
                >
                  <CheckOutlined />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title={isViewOnly ? '' : 'Mark as Completed'}>
                <button
                  className="bg-red-500 text-white text-sm px-2 py-1 my-1 rounded"
                  onClick={() => handleMarkAsComplete(milestone)}
                >
                  <BorderOutlined />
                </button>
              </Tooltip>
            )}{' '}
            {!isViewOnly && (
              <Tooltip title="Select a Target Date">
                <DatePicker
                  style={{ borderStyle: 'none', width: 40 }}
                  inputReadOnly
                  onChange={(date) => handleTargetDateChange(date, milestone)}
                />
              </Tooltip>
            )}
          </h3>

          {milestone.completionDate ? (
            <p className="text-sm">
              Completed On:{' '}
              {formatDate(milestone.completionDate, 'MMM dd, yyyy')}
            </p>
          ) : (
            <p className="text-sm">
              Target Date:{' '}
              {milestone.targetDate
                ? formatDate(milestone.targetDate, 'MMM dd, yyyy')
                : 'Not Set'}
            </p>
          )}
          <br />
        </div>
      ))}
      {!isViewOnly && <button onClick={showModal}>+ New Milestone</button>}
      <Modal
        title="Add New Milestone"
        open={isModalVisible}
        onOk={handleAddMilestone}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: 16 }}
          placeholder="Select a predefined title"
          onChange={(value) => setSelectedTitle(value)}
          value={selectedTitle}
        >
          {milestoneTitles.map((title) => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Or enter your custom title"
          value={newMilestoneTitle}
          onChange={(e) => setNewMilestoneTitle(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default MilestoneList;
