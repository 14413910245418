import React, { useState, useEffect } from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { format, addWeeks, startOfWeek, isBefore, addDays } from 'date-fns';
import { Task, DisciplineType } from '../../models/general';
import { fetchTasksByCompany } from '../../utils/api';

// Milestone options for filtering
const milestoneOptions = [
  'Schematic Delivery',
  '100% Plans Delivery',
  'AHJ Permit Approved',
];

// Convert DisciplineType enum to an array of discipline labels
const disciplines: string[] = Object.values(DisciplineType);

interface WorkLoadHeatMapProps {
  companyId: string;
}

const WorkLoadHeatMap: React.FC<WorkLoadHeatMapProps> = ({ companyId }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [xLabels, setXLabels] = useState<string[]>([]);
  const [data, setData] = useState<
    { id: string; data: { x: string; y: number }[] }[]
  >([]);
  const [selectedMilestone, setSelectedMilestone] = useState<string>(''); // State for selected milestone

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const response = await fetchTasksByCompany(companyId);
        setTasks(response.data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    loadTasks();
  }, [companyId]);

  useEffect(() => {
    const now = new Date();
    const startDate = addWeeks(now, -1);
    const endDate = addWeeks(now, 24);

    let dates: Date[] = [];
    let dateLabels: string[] = [];

    let current = startOfWeek(startDate);
    while (isBefore(current, endDate)) {
      dates.push(current);
      dateLabels.push(`${format(current, 'MMM-dd')}`);
      current = addWeeks(current, 1);
    }

    setXLabels(dateLabels);

    // Filter tasks based on selected milestone, if any
    const filteredTasks = selectedMilestone
      ? tasks.filter((task) => task.milestone?.title === selectedMilestone)
      : tasks;

    const heatmapData = disciplines.map((discipline) => ({
      id: discipline,
      data: dateLabels.map((date) => ({ x: date, y: 0 })),
    }));

    filteredTasks.forEach((task) => {
      const taskStart = task.startDate;
      const taskDue = addDays(task.startDate, task.duration);
      const discipline = task.scope;

      if (!taskStart || !taskDue || !discipline) return;

      const yIndex = disciplines.indexOf(discipline);
      if (yIndex === -1) return;

      dates.forEach((dateIntervalStart, xIndex) => {
        const dateIntervalEnd = addWeeks(dateIntervalStart, 1);
        if (
          isBefore(taskStart, dateIntervalEnd) &&
          !isBefore(taskDue, dateIntervalStart)
        ) {
          heatmapData[yIndex].data[xIndex].y += 1;
        }
      });
    });

    setData(heatmapData);
  }, [tasks, selectedMilestone]);

  return (
    <div>
      <h1>Work Load</h1>
      <label>
        Filter by Milestone:
        <select
          value={selectedMilestone}
          onChange={(e) => setSelectedMilestone(e.target.value)}
          style={{ marginLeft: '10px', marginBottom: '20px' }}
        >
          <option value="">All Milestones</option>
          {milestoneOptions.map((milestone) => (
            <option key={milestone} value={milestone}>
              {milestone}
            </option>
          ))}
        </select>
      </label>

      <div style={{ height: 400 }}>
        <ResponsiveHeatMap
          data={data}
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
          }}
          colors={{
            type: 'quantize',
            scheme: 'greens',
            minValue: 0,
            maxValue: 30,
            steps: 30,
          }}
          axisRight={null}
          axisLeft={null} // Hide y-axis labels
          margin={{ top: 60, right: 0, bottom: 60, left: 0 }}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
        />
      </div>
    </div>
  );
};

export default WorkLoadHeatMap;
