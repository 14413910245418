import React, { useEffect, useState } from 'react';
import { Task, User, Task as WorkTask } from '../../models/general';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Modal from 'react-modal';
import { updateTask, fetchTasksByAssignee } from '../../utils/api';
import {
  addWeeks,
  formatDate,
  isWithinInterval,
  parseISO,
  subWeeks,
} from 'date-fns';
import UserGanttChart from './UserGanttChart';
import { useNavigate } from 'react-router-dom';

const statuses = ['To Do', 'In Progress', 'In Review', 'Done', 'Blocked'];

// Tailwind color classes for project and discipline chips
const tailwindProjectColors = [
  'bg-red-500',
  'bg-green-500',
  'bg-blue-500',
  'bg-yellow-500',
  'bg-purple-500',
  'bg-pink-500',
  'bg-indigo-500',
  'bg-teal-500',
];

// Define color classes for each discipline
const disciplineColors: Record<string, string> = {
  civil: 'bg-blue-200 text-blue-800',
  survey: 'bg-green-200 text-green-800',
  foundation: 'bg-yellow-200 text-yellow-800',
  architecture: 'bg-red-200 text-red-800',
  electrical: 'bg-purple-200 text-purple-800',
  mechanical: 'bg-teal-200 text-teal-800',
  plumbing: 'bg-indigo-200 text-indigo-800',
  structural: 'bg-pink-200 text-pink-800',
  admin: 'bg-gray-200 text-gray-800',
};

// Utility to select a color class for a project based on its ID
const getColorForProject = (projectId: string) => {
  if (!projectId) return;
  const index =
    Array.from(projectId).reduce((sum, char) => sum + char.charCodeAt(0), 0) %
    tailwindProjectColors.length;
  return tailwindProjectColors[index];
};

// Utility to select a color class for a discipline (scope)
const getColorForDiscipline = (discipline: string) => {
  return disciplineColors[discipline] || 'bg-gray-200 text-gray-800';
};

interface TaskListProps {
  user: User;
  weeksToShow?: number;
}

const AssigneeTaskList: React.FC<TaskListProps> = ({ user, weeksToShow }) => {
  const [tasks, setTasks] = useState<WorkTask[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<WorkTask>({
    title: '',
    description: '',
    status: 'To Do',
    priority: 'Medium',
    assignee: undefined,
    milestone: undefined,
    startDate: new Date(),
    duration: 1,
    project: '',
  });

  useEffect(() => {
    fetchUserTasks();
  }, []);

  const fetchUserTasks = async () => {
    try {
      const response = await fetchTasksByAssignee(user._id);

      if (weeksToShow !== undefined) {
        setTasks(
          response.data.filter((task: Task) => {
            const now = new Date();
            const twoWeeksBefore = subWeeks(now, weeksToShow);
            const twoWeeksAfter = addWeeks(now, weeksToShow);
            const taskDate = parseISO(task.startDate as unknown as string);
            return isWithinInterval(taskDate, {
              start: twoWeeksBefore,
              end: twoWeeksAfter,
            });
          }),
        );
      } else {
        setTasks(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    }
  };

  const openModal = (task: WorkTask): void => {
    setFormData(task);
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    try {
      const updatedTask: WorkTask = {
        ...formData,
        startDate: new Date(formData.startDate),
      };
      const response = await updateTask(updatedTask._id!, updatedTask);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === updatedTask._id
            ? { ...response.data, project: task.project }
            : task,
        ),
      );
      closeModal();
    } catch (error) {
      console.error('Failed to update task', error);
    }
  };

  const handleUpdateStatus = async (taskId: string, newStatus: string) => {
    try {
      const taskToUpdate = tasks.find((task) => task._id === taskId);
      if (!taskToUpdate) return;
      const updatedTask = { ...taskToUpdate, status: newStatus };
      const response = await updateTask(taskId, updatedTask);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId
            ? { ...response.data, project: task.project }
            : task,
        ),
      );
    } catch (error) {
      console.error('Failed to update task status', error);
    }
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId !== destination.droppableId) {
      const taskId = result.draggableId;
      const newStatus = destination.droppableId;
      handleUpdateStatus(taskId, newStatus);
    }
  };

  const handleDoubleClick = (task: WorkTask) => {
    console.log(task);
    openModal(task);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ): void => {
    const { name, value } = e.target;
    if (name === 'startDate') console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        name === 'duration'
          ? parseInt(value)
          : name === 'startDate'
            ? parseISO(value + 'T06:00:00Z') // Ensure this is treated as a local date at midnight
            : value,
    }));
  };

  return (
    <div>
      {tasks.length > 0 && (
        <UserGanttChart
          tasks={tasks}
          setTasks={setTasks}
          onTaskDoubleClick={handleDoubleClick}
          // getColorForProject={getColorForProject}
        />
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="swimlane-container flex space-x-4 mt-6">
          {statuses.map((status) => (
            <Droppable droppableId={status} key={status}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="swimlane bg-gray-200 p-4 rounded-lg shadow-md w-1/5"
                >
                  <h3 className="text-xl font-bold mb-4">{status}</h3>
                  {tasks
                    .filter((task) => task.status === status)
                    .map((task, index) => (
                      <Draggable
                        key={task._id}
                        draggableId={task._id!}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="task-item bg-white p-4 rounded mb-2 shadow-md cursor-pointer"
                            onDoubleClick={() => handleDoubleClick(task)}
                          >
                            <div
                              className={`project-chip text-xs text-white px-1  capitalize rounded ${getColorForProject(task.project._id || task.project)}`}
                              onClick={() => {
                                navigate(
                                  `/project/${task.project._id || task.project}/schedule`,
                                );
                              }}
                            >
                              {task.project.name}
                            </div>
                            <div className="flex items-center space-x-2">
                              <h4 className="text-lg font-semibold">
                                {task.title}
                              </h4>
                              {task.scope && (
                                <span
                                  className={`scope-chip text-xs font-medium px-1 rounded ${getColorForDiscipline(task.scope)}`}
                                >
                                  {task.scope.charAt(0).toUpperCase() +
                                    task.scope.slice(1)}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Task Modal"
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-10"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Edit Task</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Task Title"
            required
            className="w-full px-4 py-2 border rounded-lg"
          />
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Task Description"
            className="w-full px-4 py-2 border rounded-lg"
          ></textarea>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
          >
            {statuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          <select
            name="priority"
            value={formData.priority}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
          >
            <option value="Lowest">Lowest</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
            <option value="Highest">Highest</option>
          </select>
          <input
            type="date"
            name="startDate"
            value={
              formData.startDate
                ? formatDate(formData.startDate, 'yyyy-MM-dd')
                : ''
            }
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg"
          />
          <input
            type="number"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            placeholder="Duration (days)"
            min="1"
            required
            className="w-full px-4 py-2 border rounded-lg"
          />
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={closeModal}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Update Task
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AssigneeTaskList;
