// src/api.ts

import axios from 'axios';
import { getToken, clearToken } from '../utils/tokenManager';
import useAuth from '../hooks/useAuth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token && token.length > 0) {
      config.headers = { Authorization: `Bearer ${token}` };
    } else {
      // Token is invalid or expired, optionally clear authentication data
      // Note: Clearing Recoil state and navigating should be handled within a component
      console.log('Token is invalid or expired');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error (e.g., token expired)
      // clearToken();
      // Redirect to login page if necessary
      console.log('Unauthorized, redirecting to login...');
      // Note: useHistory cannot be used directly here, consider setting a flag or state
      // to trigger a redirection in your main component
    }
    return Promise.reject(error);
  },
);

export const fetchUsers = () => api.get('/users');
export const createUser = (data: {}) => api.post('/users', data);
export const fetchUser = (id: string) => api.get(`/users/${id}`);
export const fetchUsersByCompany = (id: string) =>
  api.get(`/users/company/${id}`);
export const updateUser = (id: string, data: {}) =>
  api.put(`/users/${id}`, data);
export const deleteUser = (id: string) => api.delete(`/users/${id}`);

export const fetchProjects = () => api.get('/projects');
export const fetchProjectsByCompany = (id: string) =>
  api.get(`/projects/company/${id}`);
export const fetchProjectsByClient = (id: string) =>
  api.get(`/projects/client/${id}`);
export const createProject = (data: {}) => api.post('/projects', data);
export const fetchProject = (id: string) => api.get(`/projects/${id}`);
export const updateProject = (id: string, data: {}) =>
  api.put(`/projects/${id}`, data);
export const deleteProject = (id: string) => api.delete(`/projects/${id}`);

export const createThread = (projectId: string, threadTitle: string) =>
  api.post(`/projects/${projectId}/threads`, {
    title: threadTitle,
  });

export const sendMessage = (
  projectId: string,
  threadId: string,
  message: string,
  notify: boolean,
  isLetterAgreement: boolean,
) =>
  api.post(
    `/projects/${projectId}/threads/${threadId}/messages?sendEmail=${notify}${isLetterAgreement ? '&isLetterAgreement=true' : ''}`,
    {
      content: message,
    },
  );

export const fetchMessages = (
  projectId: string,
  threadId: string,
  page?: number,
  pageSize?: number,
) =>
  api.get(
    `projects/${projectId}/threads/${threadId}?${page ? 'page=' + page : ''}${page ? '&pageSize=' + pageSize : ''}`,
  );

export const fetchCompanies = () => api.get('/companies');
export const createCompany = (data: {}) => api.post('/companies', data);
export const fetchCompany = (id: string) => api.get(`/companies/${id}`);
export const updateCompany = (id: string, data: {}) =>
  api.put(`/companies/${id}`, data);
export const deleteCompany = (id: string) => api.delete(`/companies/${id}`);

export const signup = (data: {}) => api.post('/auth/signup', data);
export const signin = (data: {}) => api.post('/auth/signin', data);
export const getLinkToken = (data: {}) => api.post('/auth/getlinktoken', data);
export const checkLinkToken = (data: {}) =>
  api.post('/auth/checklinktoken', data);

export const uploadFile = async (
  projectId: string,
  companyId: string,
  file: File,
  fileName: string,
  description: string,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', fileName);
  formData.append('description', description);

  return api.post(
    `/file-upload?projectId=${projectId}&companyId=${companyId}`,
    formData,
  );
};
export const getSignedUrl = (data: {}) =>
  api.post(`/file-upload/signed-url`, data);

// Milestone API functions
export const createMilestone = (data: {}) => api.post('/milestone', data);
export const fetchMilestones = () => api.get('/milestone');
export const fetchMilestone = (id: string) => api.get(`/milestone/${id}`);
export const fetchMilestonesByProject = (projectId: string) =>
  api.get(`/milestone/project/${projectId}`);
export const updateMilestone = (id: string, data: {}) =>
  api.patch(`/milestone/${id}`, data);
export const deleteMilestone = (id: string) => api.delete(`/milestone/${id}`);

// Task API functions
export const createTask = (data: {}) => api.post('/task', data);
export const fetchTasksByProject = (projectId: string) =>
  api.get(`/task/project/${projectId}`);
export const fetchTasksByAssignee = (userId: string) =>
  api.get(`/task/assignee/${userId}`);
export const fetchTasksByCompany = (companyId: string) =>
  api.get(`/task/company/${companyId}`);
export const updateTask = (id: string, data: {}) =>
  api.patch(`/task/${id}`, data);
export const deleteTask = (id: string) => api.delete(`/task/${id}`);

// Template API functions
export const createTemplate = (data: {}) => api.post('/template', data);
export const fetchTemplates = () => api.get('/template');
export const fetchTemplate = (id: string) => api.get(`/template/${id}`);
export const fetchTemplatesByCompany = (companyId: string) =>
  api.get(`/template/company/${companyId}`);
export const updateTemplate = (id: string, data: {}) =>
  api.put(`/template/${id}`, data);
export const deleteTemplate = (id: string) => api.delete(`/template/${id}`);

// Add more API calls as needed...
