import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
  fetchMilestonesByProject,
  fetchProject,
  sendMessage,
  updateMilestone,
  updateProject,
} from '../utils/api';
import Terms from '../components/Exhibits/Terms';
import AdditionalServices from '../components/Exhibits/AdditionalServices';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  activeProjectState,
  lOETotals,
  milestonesState,
} from '../atoms/project.atom';
import { activeCompanyState } from '../atoms/new.company.atom';
import { activeUserState } from '../atoms/user.atom';
import letterHead from './../images/letterHead.png';
import LetterDisplay from '../components/HTMLPDF';
import { formatCurrency, workFlowStatuses } from '../utils/misc';
import ClickToSign from '../components/ClickToSign';
import { useReactToPrint } from 'react-to-print';
import { clearToken } from '../utils/tokenManager';
import { Company, Milestone } from '../models/general';
import { addDays } from 'date-fns';

const SignAgreementPage = () => {
  const { projectId } = useParams();
  const targetRef = useRef();
  const [activeProject, setActiveProject] = useRecoilState(activeProjectState);
  const [activeCompany, setActiveCompany] = useRecoilState(activeCompanyState);
  const [activeMilestones, setActiveMilestones] =
    useRecoilState(milestonesState);
  const [activeUser, setActiveUser] = useRecoilState(activeUserState);
  const totals = useRecoilValue(lOETotals);
  const [signDate, setSignDate] = useState<Date | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [emailContent, setEmailContent] = useState<string>('');
  const [signature, setSignature] = useState<string>('');
  const [signature1, setSignature1] = useState<string>('');
  const [signature2, setSignature2] = useState<string>('');
  const [signature3, setSignature3] = useState<string>('');
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const sigCanvas = useRef<SignatureCanvas>(null);
  const navigate = useNavigate();

  // Fetch agreement content on mount
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchProject(projectId);
        const projectData = response.data;
        setActiveProject(projectData || {});
        setSignature(projectData.signature || '');
        setActiveMilestones(projectData.milestones);
      } catch (error) {
        console.error('Failed to fetch project', error);
      }
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    if (activeCompany && activeProject && activeProject.client)
      setIsAuthorized(activeCompany._id === activeProject.client._id);
  }, [activeCompany._id, activeProject.client]);

  const signOut = async () => {
    try {
      clearToken();
      setActiveUser(null);
      setActiveCompany({} as Company);
      navigate('/');
    } catch (error) {
      console.error('Signin failed', error);
    }
  };

  const handleSave = async () => {
    if (sigCanvas.current?.isEmpty()) return;
    const currentDate = new Date();
    setSignDate(currentDate);
    const signatureData = sigCanvas.current
      ?.getTrimmedCanvas()
      .toDataURL('image/png');
    setSignature(signatureData);
    setShowModal(false);
    try {
      await updateProject(projectId, { signature: signatureData });
    } catch (error) {
      console.error('Failed to save signature', error);
    }
  };

  const handleRequestChanges = () => {
    setShowEmailModal(true);
  };
  const handleCancel = () => {
    navigate('/guest');
  };

  const handleApproveAndSubmit = async () => {
    console.log('Approving and submitting...');
    const messageContent = `
${activeProject.client.owner.firstName}, the signed agreement has been recieved for ${activeProject.address}. We will be in touch shortly.

We are looking forward to working with you.

Sincerely,

${activeProject.company.name}
      `;

    for (const milestone of activeMilestones || []) {
      if (!milestone._id) {
        alert('OLD MILESTONE FOUND, changes not saved');
        return;
      }

      if (milestone.title === 'Letter Agreement Signed') {
        await updateMilestone(milestone._id, {
          completionDate: new Date(),
        });
      } else if (milestone.title === 'Deposit Sent') {
        await updateMilestone(milestone._id, {
          targetDate: addDays(new Date(), 2),
        });
      } else if (milestone.title === 'Paid Deposit') {
        await updateMilestone(milestone._id, {
          targetDate: addDays(new Date(), 7),
        });
      }
    }
    try {
      const updatedResponse = await fetchMilestonesByProject(activeProject._id);
      setActiveMilestones(updatedResponse.data);
    } catch (e) {
      console.log('Failed to update Milestones');
    }
    const updatedProject = {
      ...activeProject,
      workFlowStatus: 'letter agreement - signed',
      signedContracts: [
        ...(activeProject.signedContracts || []),
        targetRef.current.innerHTML,
      ],
    };
    try {
      await updateProject(activeProject._id, updatedProject);

      // Check if "Letter Agreement" thread exists in activeProject.threads
      let thread = activeProject?.threads?.find(
        (t) => t.title === 'Letter Agreement',
      );

      if (!thread) throw Error('No thread created or found');

      // Send message with notification
      await sendMessage(
        activeProject._id,
        thread._id,
        messageContent,
        true,
        false,
      );

      setShowConfirmationModal(true);
      // Implement logic for approving and submitting here
    } catch {
      alert('project fialed to save. Please let us know and try again later.');
    }
  };

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const closeEmailModal = () => {
    setShowEmailModal(false);
  };

  const sendEmail = async () => {
    try {
      // Check if "Letter Agreement" thread exists in activeProject.threads
      let thread = activeProject?.threads?.find(
        (t) => t.title === 'Letter Agreement',
      );

      if (!thread) throw Error('No thread created or found');

      // Send message with notification
      await sendMessage(
        activeProject._id,
        thread._id,
        emailContent,
        true,
        false,
      );
      setEmailContent('');
      closeEmailModal();
      alert('Email sent successfully');
    } catch {
      alert('Message Failed to send. Please try again.');
    }
  };
  if (!isAuthorized) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="container mx-auto my-4 p-4 border rounded shadow align-middle w-1/2">
          <h1 className="text-3xl font-bold mb-4">
            Welcome {activeUser.firstName} {activeUser.lastName}{' '}
          </h1>
          <p>
            Sorry, you must be the signer of this document to view this page.
          </p>
          <button
            onClick={() => signOut()}
            className="px-4 mt-5 py-1 text-sm bg-blue-500 text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
          >
            Log Out
          </button>
        </div>
      </div>
    );
  }
  return activeProject.signedContracts &&
    activeProject.signedContracts.length > 0 ? (
    <div className="container mx-auto my-4 p-4 rounded">
      <div className="flex mb-4 justify-center">
        {/* <button
          onClick={handlePrint}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Save a Copy
        </button> */}
        <button
          onClick={handleCancel}
          className="bg-yellow-500 text-white px-4 py-2 rounded mx-2"
        >
          Back To Dashboard
        </button>
      </div>
      <LetterDisplay content={activeProject.signedContracts[0]} />
    </div>
  ) : (
    <div className="container mx-auto my-4 p-4 border rounded shadow">
      <h1 className="text-3xl font-bold mb-4">
        Welcome {activeUser.firstName} {activeUser.lastName}{' '}
      </h1>
      <p>Please review the following Proposal for approval.</p>
      <div className="mb-4">
        <div className="bg-gray-100 p-4 rounded">
          {activeProject.name ? (
            <div ref={targetRef} className="quill">
              <div className=" bg-white p-6 rounded-lg shadow-lg mt-2">
                <img
                  className="w-100 inline"
                  src={letterHead}
                  alt="Abacus Engineering Inc."
                ></img>
                <LetterDisplay content={activeProject.agreementContent} />
                <h2 className="font-bold text-2xl">Fee Schedule</h2>
                <br />
                <div className="mx-6">
                  {activeProject?.disciplines?.map((discipline: string) => (
                    <>
                      <div className="grid grid-cols-2">
                        <p>{discipline.toUpperCase()} Design & Drawings</p>
                        <p>{formatCurrency(totals[discipline])}</p>
                      </div>
                    </>
                  ))}
                  <div className="grid grid-cols-2">
                    <p className="font-bold">Total</p>
                    <p className="font-bold">{formatCurrency(totals?.total)}</p>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="grid grid-cols-2">
                  <div className="col-span-1">
                    <h3>Client: {activeProject.client?.name}</h3>
                    <p>
                      Representative: {activeProject.client?.owner?.firstName}{' '}
                      {activeProject.client?.owner.lastName}
                    </p>
                    <br />
                    <p>
                      Signature:{' '}
                      {signature ? (
                        <ClickToSign
                          onSign={() => setSignature1(signature)}
                          appliedSignature={signature1}
                        />
                      ) : (
                        <button
                          onClick={() => setShowModal(true)}
                          className="bg-green-500 text-white px-4 py-2 rounded"
                        >
                          Enter Signature
                        </button>
                      )}
                    </p>
                    {!signDate && signature1 && (
                      <p className="mt-4 text-gray-700">
                        <p>Date : ______________________</p>
                      </p>
                    )}
                    {signDate && (
                      <p className="mt-4 text-gray-700">
                        Signed on: {signDate.toLocaleDateString()} at{' '}
                        {signDate.toLocaleTimeString()}
                      </p>
                    )}
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
                <Terms />
                <div className="col-span-1">
                  <p>
                    Signature:{' '}
                    {signature ? (
                      <ClickToSign
                        onSign={() => setSignature2(signature)}
                        appliedSignature={signature2}
                      />
                    ) : (
                      <button
                        onClick={() => setShowModal(true)}
                        className="bg-green-500 text-white px-4 py-2 rounded"
                      >
                        Enter Signature
                      </button>
                    )}
                  </p>
                  {!signDate && signature2 && (
                    <p className="mt-4 text-gray-700">
                      <p>Date : ______________________</p>
                    </p>
                  )}
                  {signDate && signature2 && (
                    <p className="mt-4 text-gray-700">
                      Signed on: {signDate.toLocaleDateString()} at{' '}
                      {signDate.toLocaleTimeString()}
                    </p>
                  )}
                </div>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
                <AdditionalServices />
                <div className="col-span-1">
                  <p>
                    Signature:{' '}
                    {signature ? (
                      <ClickToSign
                        onSign={() => setSignature3(signature)}
                        appliedSignature={signature3}
                      />
                    ) : (
                      <button
                        onClick={() => setShowModal(true)}
                        className="bg-green-500 text-white px-4 py-2 rounded"
                      >
                        Enter Signature
                      </button>
                    )}
                  </p>
                  {!signDate && signature3 && (
                    <p className="mt-4 text-gray-700">
                      <p>Date : ______________________</p>
                    </p>
                  )}
                  {signDate && signature3 && (
                    <p className="mt-4 text-gray-700">
                      Signed on: {signDate.toLocaleDateString()} at{' '}
                      {signDate.toLocaleTimeString()}
                    </p>
                  )}
                </div>
              </div>
              <br />
            </div>
          ) : (
            <p>No agreement content available.</p>
          )}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-1/2">
            <h2 className="text-2xl font-semibold mb-4">
              Abacus Engineering - Letter Agreement Review
            </h2>
            <p>Please enter a signature to apply on approval.</p>
            <div className="mb-4">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  className: 'w-full h-32 border-2 border-gray-300 rounded',
                }}
                backgroundColor="rgba(0,0,0,0)"
              />
              <div className="mt-2">
                <button
                  onClick={() => sigCanvas.current?.clear()}
                  className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                >
                  Clear
                </button>
                <button
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Save Signature
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Footer with Action Buttons */}
      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 border-t border-gray-300 flex justify-between items-center">
        {!!signature1 && !!signature2 && !!signature3 ? (
          <button
            onClick={handleApproveAndSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Approve & Submit
          </button>
        ) : (
          <span className="text-red-500 font-semibold">
            Scroll to Apply Signatures
          </span>
        )}
        <button
          onClick={handleCancel}
          className="bg-yellow-500 text-white px-4 py-2 rounded"
        >
          Cancel
        </button>
        <button
          onClick={handleRequestChanges}
          className="bg-yellow-500 text-white px-4 py-2 rounded"
        >
          Request Changes
        </button>
      </div>
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-1/3">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Thank you for choosing Abacus Engineering Inc.
            </h2>
            <p className="mb-4 text-center">
              We appreciate the opportunity to work with you. Your agreement has
              been successfully submitted.
            </p>

            <div className="flex mb-4 justify-center">
              <button
                onClick={handlePrint}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Save a Copy
              </button>
              <button
                onClick={handleCancel}
                className="bg-yellow-500 text-white px-4 py-2 rounded mx-2"
              >
                Back To Dashboard
              </button>
            </div>
            {/* <p className="mb-4 text-center text-gray-500">
              Your signed agreement will be available for review any time by
              viewing you{' '}
              <a href="/" className="text-blue-500">
                project dashboard
              </a>
              .
            </p> */}
          </div>
        </div>
      )}
      {showEmailModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-1/2">
            <h2 className="text-2xl font-semibold mb-4">Request Changes</h2>
            <textarea
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
              rows={10}
              className="w-full p-2 border rounded mb-4"
              placeholder="Please describe the changes you would like to request..."
            />
            <div className="flex justify-end">
              <button
                onClick={closeEmailModal}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={sendEmail}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignAgreementPage;
