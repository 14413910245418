import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { lOETotals, milestonesState } from '../atoms/project.atom';
import ConstructionCostEstimate from '../components/ConstructionCostEstimate';
import LOEItem from '../components/LOEItem';
import { LOEItemI, Milestone, Project } from '../models/general';
import { hourlyRates } from '../utils/misc';
import { addDays } from 'date-fns';
import { fetchMilestonesByProject, updateMilestone } from '../utils/api';

interface OutletContext {
  activeProject: Project;
  updateProjectList: (project: Project) => void;
}

const LOE: React.FC = () => {
  const navigate = useNavigate();
  const { activeProject, updateProjectList } =
    useOutletContext<OutletContext>();
  const [loeItems, setLoeItems] = useState<LOEItemI[]>(
    activeProject.loeEstimate || [],
  );
  const totals = useRecoilValue(lOETotals);
  const [activeMilestones, setActiveMilestones] =
    useRecoilState(milestonesState);

  const isEstimating =
    activeProject.workFlowStatus === 'estimating' ||
    activeProject.workFlowStatus === 'initiated';
  const handleLOEItemChange = (index: number, updatedItem: LOEItemI) => {
    const updatedLoeItems = [...loeItems];
    console.log('Updated LOE');
    updatedLoeItems[index] = updatedItem;
    setLoeItems(updatedLoeItems);
    updateProjectList({
      ...activeProject,
      loeEstimate: updatedLoeItems,
      workFlowStatus: 'estimating',
    });
  };

  const handleAddLOEItem = (discipline: string) => {
    const newItem: LOEItemI = {
      discipline: discipline,
      title: '',
      hoursPE: 0,
      hoursEIT: 0,
      hoursDraftsman: 0,
      hoursAdmin: 0,
      dollarsSub: 0,
      dollarsMilage: 0,
    };
    const updatedLoeItems = [...loeItems, newItem];
    setLoeItems(updatedLoeItems);
    updateProjectList({
      ...activeProject,
      loeEstimate: updatedLoeItems,
      workFlowStatus: 'estimating',
    });
  };

  const handleRemoveLOEItem = (index: number) => {
    const updatedLoeItems = loeItems.filter((_, i) => i !== index);
    setLoeItems(updatedLoeItems);
    updateProjectList({
      ...activeProject,
      loeEstimate: updatedLoeItems,
      workFlowStatus: 'estimating',
    });
  };

  const handleSaveEstimate = async (updateStatus: boolean) => {
    if (updateStatus) {
      for (const milestone of activeMilestones || []) {
        if (!milestone._id) {
          alert('OLD MILESTONE FOUND, changes not saved');
          return;
        }

        if (milestone.title === 'Estimate Completed') {
          await updateMilestone(milestone._id, {
            completionDate: new Date(),
          });
        } else if (milestone.title === 'Letter Agreement Sent') {
          await updateMilestone(milestone._id, {
            targetDate: addDays(new Date(), 3),
          });
        }
      }
      try {
        const updatedResponse = await fetchMilestonesByProject(
          activeProject._id,
        );
        setActiveMilestones(updatedResponse.data);
      } catch (e) {
        console.log('Failed to update Milestones');
      }

      updateProjectList({
        ...activeProject,
        workFlowStatus: 'estimate - finished',
      });
    } else {
      updateProjectList({
        ...activeProject,
        workFlowStatus: 'estimating',
      });
    }

    navigate(`/project/${activeProject._id}`);
  };

  const handleReOpenEstimate = () => {
    updateProjectList({
      ...activeProject,
      workFlowStatus: 'estimating',
    });
  };
  const handleMakeTemplate = () => {
    let template = { 'matts scope': {} };
    activeProject.disciplines.forEach((discipline: string) => {
      template['matts scope'][discipline] = { lineItems: [] };
      loeItems.forEach((item) => {
        if (item.discipline === discipline)
          template['matts scope'][discipline].lineItems.push({
            title: item.title,
            hoursPE: item.hoursPE,
            hoursEIT: item.hoursEIT,
            hoursDraftsman: item.hoursDraftsman,
            hoursAdmin: item.hoursAdmin,
            dollarsSub: item.dollarsSub,
            dollarsMilage: item.dollarsMilage,
          });
      });
    });
    console.log(template);
  };

  return (
    <div className="">
      <h4 className="text-gray-600 text-2xl font-semibold mt-2">
        Estimate Total - ${totals.total}
      </h4>
      {activeProject.disciplines.map((discipline: string) => (
        <div className=" bg-white p-6 rounded-lg shadow-lg mt-2">
          <br />
          <div>
            <div className="text-gray-600 text-xl font-semibold capitalize border-b-2">
              {discipline} - ${(totals as any)[discipline]}
            </div>
          </div>

          <div className="grid grid-cols-11">
            <div className="col-span-4">Task Description</div>
            <div className="col-span-1 px-4">Total</div>
            <div className="col-span-1 px-4">
              PE Hours (${hourlyRates.pe}/hr)
            </div>
            <div className="col-span-1 px-4">
              EIT Hours (${hourlyRates.eit}/hr)
            </div>
            <div className="col-span-1 px-4">
              Draftsman Hours (${hourlyRates.draftsman}/hr)
            </div>
            <div className="col-span-1 px-4">Sub Fees</div>
            <div className="col-span-1 px-4">Milage Cost</div>
          </div>
          <div className="grid grid-cols-11">
            {/* The Remove button is wired up, but not triggering a rerender */}
            {isEstimating &&
              loeItems.map((item, index) => {
                if (item.discipline === discipline)
                  return (
                    <LOEItem
                      item={item}
                      index={index}
                      onChange={handleLOEItemChange}
                      onRemove={handleRemoveLOEItem}
                    />
                  );
              })}
            {isEstimating && (
              <div
                className="rounded bg-gray-500 px-4 py-1 text-white w-20 font-small my-2 cursor-pointer"
                onClick={() => handleAddLOEItem(discipline)}
              >
                + Item
              </div>
            )}
            {!isEstimating && (
              <h1 className="col-span-6 text-2xl">
                This Estimate has been Finalized and is no longer editable.
              </h1>
            )}
            {/* This button needs to be bound to a function that creates a new item and pushes it to the store... Will also need to update the list */}
          </div>
        </div>
      ))}
      <div className="grid grid-cols-3 content-center mt-2">
        {isEstimating && (
          <>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
              onClick={() => handleSaveEstimate(true)}
            >
              Complete Estimate
            </button>
          </>
        )}
        {!isEstimating && (
          <button
            type="submit"
            className="rounded bg-red-500 px-4 py-2 text-white w-60 font-medium mx-auto"
            onClick={() => handleReOpenEstimate()}
          >
            Re-Open Estimate
          </button>
        )}
        {/* <button
          type="submit"
          className="rounded bg-red-500 px-4 py-2 text-white w-60 font-medium mx-auto"
          onClick={() => handleMakeTemplate()}
        >
          Make Template
        </button> */}
      </div>
    </div>
  );
};

export default LOE;
