import React from 'react';
import { Task as WorkTask } from '../../models/general';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { MdPersonOutline } from 'react-icons/md';

interface TaskListProps {
  tasks: WorkTask[];
  onEdit: (task: WorkTask) => void;
  onDelete?: (taskId: string) => void;
  onUpdateStatus: (taskId: string, newStatus: string) => void;
}

const statuses = ['To Do', 'In Progress', 'In Review', 'Done', 'Blocked'];

const ProjectTaskList: React.FC<TaskListProps> = ({
  tasks,
  onEdit,
  onDelete,
  onUpdateStatus,
}) => {
  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const taskId = result.draggableId;
      const newStatus = destination.droppableId;
      onUpdateStatus(taskId, newStatus);
    }
  };

  return tasks.length > 0 ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="swimlane-container flex space-x-4 mt-6">
        {statuses.map((status) => (
          <Droppable droppableId={status} key={status}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="swimlane bg-gray-200 p-4 rounded-lg shadow-md w-1/5"
              >
                <h3 className="text-xl font-bold mb-4">{status}</h3>
                {tasks
                  .filter((task) => task.status === status)
                  .map((task, index) => (
                    <Draggable
                      key={task._id}
                      draggableId={task._id!}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="task-item bg-white p-4 rounded mb-2 shadow-md"
                        >
                          <h4 className="text-lg font-semibold">
                            {task.scope?.toUpperCase()} - {task.title}
                          </h4>
                          <p className="text-sm">
                            Milestone: {task.milestone?.title}
                          </p>
                          <p className="text-sm">{task.description}</p>
                          <p className="text-sm">Priority: {task.priority}</p>
                          <p className="text-sm flex items-center">
                            <MdPersonOutline
                              style={{ marginRight: '8px', color: 'grey' }}
                            />
                            Assignee: {task.assignee?.firstName}{' '}
                            {task.assignee?.lastName}
                          </p>
                          <div className="flex mt-2 space-x-2">
                            <button
                              onClick={() => onEdit(task)}
                              className="bg-yellow-500 text-white px-4 py-1 rounded hover:bg-yellow-600"
                            >
                              Edit
                            </button>
                            {onDelete && (
                              <button
                                onClick={() => onDelete(task._id!)}
                                className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  ) : (
    <></>
  );
};

export default ProjectTaskList;
