// src/../atoms/user.atom.ts

import { atom, selector } from 'recoil';
import {
  fetchUsers,
  createUser,
  fetchUser,
  updateUser,
  deleteUser,
} from '../utils/api';
import { User } from '../models/general';
import { startTransition } from 'react';

export const activeUserState = atom({
  key: 'activeUserState',
  default: null,
});

export const userListState = atom({
  key: 'userListState',
  default: [] as User[],
});

// Persisting user changes
export const userCRUDState = selector({
  key: 'userCRUDState',
  get: ({ get }) => get(userListState),
  set: ({ set, get }, newValue) => {
    const currentUsers = get(userListState);

    if (newValue.action === 'create') {
      createUser(newValue.data).then((response) => {
        set(userListState, [...currentUsers, response.data]);
      });
    } else if (newValue.action === 'update') {
      updateUser(newValue.id, newValue.data).then((response) => {
        set(
          userListState,
          currentUsers.map((user: User) =>
            user._id === newValue.id ? response.data : user,
          ),
        );
      });
    } else if (newValue.action === 'delete') {
      deleteUser(newValue.id).then(() => {
        set(
          userListState,
          currentUsers.filter((user: User) => user._id !== newValue.id),
        );
      });
    }
  },
});

// Selector to compute if the user has specific authorizations
export const activeAuths = selector<object>({
  key: 'activeAuths',
  get: ({ get }) => {
    const user = get(activeUserState);
    console.log('getting auths', user);
    if (!user) return {};
    return {
      hasOwner: user.userType === 'Owner',
      hasAccounting:
        user.userType === 'Owner' ||
        user?.authorizations?.includes('Accounting'),
      hasEstimating:
        user.userType === 'Owner' ||
        user?.authorizations?.includes('Estimating'),
      hasDesign:
        user.userType === 'Owner' ||
        user?.authorizations?.includes('Designing'),
      hasLegal:
        user.userType === 'Owner' || user?.authorizations?.includes('Legal'),
      hasSuperUser: user?.authorizations?.includes('SuperUser'),
    };
  },
});
