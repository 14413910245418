import React, { useEffect, useState } from 'react';
import { Radio, Select, Tooltip } from 'antd'; // Assuming you are using antd for date picker
import { Milestone, Project } from '../models/general';
import { compareAsc, formatDate } from 'date-fns';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';

const { Option } = Select;

const MilestoneListView: React.FC<{
  project: Project;
}> = ({ project }) => {
  const [activeMilestones, setActiveMilestones] = useState([] as Milestone[]);

  useEffect(() => {
    if (project.milestones) setActiveMilestones(project.milestones);
  }, []);
  const [filter, setFilter] = useState<'all' | 'completed' | 'incomplete'>(
    'all',
  );

  const handleFilterChange = (e: any) => {
    setFilter(e.target.value);
  };

  const filteredMilestones = activeMilestones
    .filter((milestone) => {
      if (filter === 'completed') {
        return milestone.completionDate;
      } else if (filter === 'incomplete') {
        return !milestone.completionDate;
      }
      return true; // If "all" is selected, show all milestones
    })
    .sort((a, b) => {
      if (a.sequence == undefined || b.sequence == undefined) return 0;
      return a.sequence - b.sequence;
    });

  return (
    <div>
      <Radio.Group
        onChange={handleFilterChange}
        value={filter}
        style={{ marginBottom: 16 }}
      >
        <Radio.Button value="all">All</Radio.Button>
        <Radio.Button value="completed">Completed</Radio.Button>
        <Radio.Button value="incomplete">Future</Radio.Button>
      </Radio.Group>
      {filteredMilestones.map((milestone, index) => (
        <div key={index} className="milestone-item">
          <h3 className="font-bold">
            {milestone.title}{' '}
            {milestone.completionDate ? (
              <Tooltip title={''}>
                <button
                  className="bg-blue-500 text-white text-sm px-2 py-1 my-1 rounded"
                  onClick={() => {
                    return;
                  }}
                >
                  <CheckOutlined />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title={''}>
                <button
                  className="bg-red-500 text-white text-sm px-2 py-1 my-1 rounded"
                  onClick={() => {
                    return;
                  }}
                >
                  <BorderOutlined />
                </button>
              </Tooltip>
            )}
          </h3>

          {milestone.completionDate ? (
            <p className="text-sm">
              Completed On:{' '}
              {formatDate(milestone.completionDate, 'MMM dd, yyyy')}
            </p>
          ) : (
            <p className="text-sm">
              Target Date:{' '}
              {milestone.targetDate
                ? formatDate(milestone.targetDate, 'MMM dd, yyyy')
                : 'Not Set'}
            </p>
          )}
          <br />
        </div>
      ))}
    </div>
  );
};

export default MilestoneListView;
