// ProjectSchedulePreview.tsx
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Gantt, Task as GanttTask, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Task as WorkTask, Project, Milestone } from '../../models/general';
import { deleteTask, fetchTasksByProject, updateTask } from '../../utils/api';
import ProjectTaskList from './ProjectTaskList';

interface ProjectSchedulePreviewProps {
  project: Project;
}

const ProjectSchedulePreview: React.FC<ProjectSchedulePreviewProps> = ({
  project,
}) => {
  const [tasks, setTasks] = useState<WorkTask[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentTaskId, setCurrentTaskId] = useState<string | null>(null);
  const [activeMilestones, setActiveMilestones] = useState<Milestone[]>(
    project.milestones || [],
  );
  const [formData, setFormData] = useState<WorkTask>({
    title: '',
    description: '',
    status: 'To Do',
    priority: 'Medium',
    assignee: undefined,
    reporter: undefined,
    milestone: undefined,
    startDate: new Date(),
    duration: 1,
    project: '',
  });

  useEffect(() => {
    fetchUserTasks();
  }, []);

  const fetchUserTasks = async () => {
    try {
      setIsLoading(true);
      const response = await fetchTasksByProject(project._id);
      setTasks(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    }
  };

  const convertTasks = (tasks: WorkTask[]): GanttTask[] => {
    // Sort tasks by start date in ascending order
    const sortedTasks = tasks.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    );

    // Convert sorted tasks to Gantt tasks
    return sortedTasks.map((task) => {
      let progress = 0;

      // Set progress based on the task status
      switch (task.status) {
        case 'Done':
          progress = 100;
          break;
        case 'Blocked':
          progress = 0;
          break;
        case 'In Progress':
          progress = 50;
          break;
        case 'In Review':
          progress = 75;
          break;
        default:
          progress = 0;
          break;
      }

      // Update task name to display scope in uppercase followed by title
      const taskName = `${task.scope?.toUpperCase() || 'GENERAL'}: ${
        task.title
      } - ${task.status}`;

      return {
        id: task._id || '1',
        name: taskName,
        start: new Date(task.startDate) || new Date(),
        end:
          new Date(
            new Date(task.startDate).getTime() +
              task.duration * 24 * 60 * 60 * 1000,
          ) || new Date(),
        type: 'task',
        progress: progress,
        dependencies: [], // Add dependencies if applicable
      };
    });
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setCurrentTaskId(null);
    setFormData({
      title: '',
      description: '',
      scope: '',
      status: 'To Do',
      priority: 'Medium',
      assignee: undefined,
      reporter: undefined,
      startDate: new Date(),
      duration: 1,
      project: project._id || '',
    });
  };

  const handleEdit = (task: WorkTask): void => {
    setFormData({
      title: task.title,
      description: task.description,
      scope: task.scope,
      status: task.status,
      priority: task.priority,
      assignee: task.assignee,
      reporter: task.reporter,
      milestone: task.milestone,
      startDate: task.startDate,
      duration: task.duration,
      project: task.project,
    });
    setCurrentTaskId(task._id);
    setIsEditMode(true);
    openModal();
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ): void => {
    const { name, value } = e.target;
    if (name === 'assignee') {
      const selectedUser = project.contacts?.find(
        (contact) => contact._id === value,
      );
      console.log('finding User', name, value, selectedUser);
      setFormData({
        ...formData,
        assignee: selectedUser,
      });
    }
    if (name === 'milestone') {
      console.log('finding milestone', name, value);
      const selectedMilestone = activeMilestones.find(
        (milestone) => milestone._id === value,
      );
      setFormData({
        ...formData,
        milestone: selectedMilestone,
      });
    } else if (name !== 'assignee') {
      console.log('2', formData, name, value);
      setFormData({
        ...formData,
        [name]: name === 'duration' ? parseInt(value) : value,
      });
    }
  };

  const handleUpdateStatus = async (taskId: string, newStatus: string) => {
    try {
      // Find the task that needs to be updated
      const taskToUpdate = tasks.find((task) => task._id === taskId);
      if (!taskToUpdate) return;

      // Update the status in the local state immediately for a responsive UI
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, status: newStatus } : task,
        ),
      );

      // Prepare the updated task data to send to the API
      const updatedTask = { ...taskToUpdate, status: newStatus };

      // Call the updateTask API function to persist the change
      const response = await updateTask(taskId, updatedTask);
      const newTasks = tasks.map((task) => {
        return task._id === taskId ? response.data : task;
      });
      setTasks(newTasks);
    } catch (error) {
      console.error('Failed to update task status', error);
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    try {
      const newTask: WorkTask = {
        ...formData,
        project: project._id,
        company: project.company || project.company._id,
        startDate: new Date(formData.startDate),
      };
      if (isEditMode && currentTaskId) {
        const response = await updateTask(currentTaskId, newTask);
        const newTasks = tasks.map((task) => {
          return task._id === currentTaskId ? response.data : task;
        });
        setTasks(newTasks);
      } else {
        const response = await createTask(newTask);
        setTasks([...tasks, response.data]);
      }
      closeModal();
    } catch (error) {
      console.error('Failed to create or update task', error);
    }
  };

  const handleDelete = async (taskId: string): Promise<void> => {
    try {
      await deleteTask(taskId);
      setTasks(tasks.filter((task) => task._id !== taskId));
    } catch (error) {
      console.error('Failed to delete task', error);
    }
  };

  const handleGanttDoubleClick = (ganttTask: GanttTask) => {
    const correspondingTask = tasks.find((t) => t._id === ganttTask.id);
    if (correspondingTask) {
      // Handle task double-click, e.g., open task details
      console.log('Task double-clicked:', correspondingTask);
    }
  };

  const handleTaskChange = async (updatedTask: GanttTask) => {
    const correspondingTask = tasks.find((t) => t._id === updatedTask.id);
    if (correspondingTask) {
      correspondingTask.startDate = updatedTask.start;
      correspondingTask.duration = Math.ceil(
        (updatedTask.end.getTime() - updatedTask.start.getTime()) /
          (24 * 60 * 60 * 1000),
      );
      delete correspondingTask.__v;
      try {
        // Optimistically update the task in the local state
        setTasks(
          tasks.map((task) =>
            task._id === correspondingTask._id ? correspondingTask : task,
          ),
        );

        // Update the task on the server
        const response = await updateTask(
          correspondingTask._id,
          correspondingTask,
        );
        // Update the task with the response from the server
        setTasks(
          tasks.map((task) =>
            task._id === correspondingTask._id ? response.data : task,
          ),
        );
      } catch (error) {
        console.error('Failed to update task', error);
      }
    }
  };
  if (isLoading) {
    return <div>Tasks are Loading...</div>;
  } else if (tasks.length > 0) {
    return (
      <>
        <Gantt
          tasks={convertTasks(tasks)}
          viewMode={ViewMode.Day}
          onDoubleClick={handleGanttDoubleClick}
          onDateChange={handleTaskChange}
          listCellWidth={'0'}
          todayColor="#909090"
          ganttHeight={300}
        />
        <ProjectTaskList
          tasks={tasks}
          onEdit={handleEdit}
          onUpdateStatus={handleUpdateStatus}
        />
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Task Modal"
          className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-10"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <h2 className="text-2xl font-bold mb-4">
            {isEditMode ? 'Edit Task' : 'Add Task'}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <select
              name="scope"
              value={formData.scope || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="" disabled>
                Select a Discipline
              </option>
              {project.disciplines.map((discipline) => (
                <option key={discipline} value={discipline}>
                  {discipline}
                </option>
              ))}
            </select>
            <select
              name="milestone"
              value={formData.milestone?._id || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="" disabled>
                Select a Milestone
              </option>
              {activeMilestones.map((milestone) => (
                <option key={milestone._id} value={milestone._id}>
                  {milestone.title}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Task Title"
              required
              className="w-full px-4 py-2 border rounded-lg"
            />
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Task Description"
              className="w-full px-4 py-2 border rounded-lg"
            ></textarea>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="To Do">To Do</option>
              <option value="In Progress">In Progress</option>
              <option value="In Review">In Review</option>
              <option value="Done">Done</option>
              <option value="Blocked">Blocked</option>
            </select>
            <select
              name="priority"
              value={formData.priority}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="Lowest">Lowest</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Highest">Highest</option>
            </select>
            <select
              name="assignee"
              value={formData.assignee?._id || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="" disabled>
                Select Assignee
              </option>
              {project.contacts?.map((contact) => (
                <option key={contact._id} value={contact._id}>
                  {contact.firstName} {contact.lastName}
                </option>
              ))}
            </select>
            <input
              type="date"
              name="startDate"
              value={formData.startDate.toString().slice(0, 10)}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg"
            />
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              placeholder="Duration (days)"
              min="1"
              required
              className="w-full px-4 py-2 border rounded-lg"
            />
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                {isEditMode ? 'Update Task' : 'Add Task'}
              </button>
            </div>
          </form>
        </Modal>
      </>
    );
  } else {
    return <div>No Schedule Found.</div>;
  }
};

export default ProjectSchedulePreview;
